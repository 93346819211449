import * as React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/Layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    {/* <Seo title="404: Not found" /> */}
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn't exist... the sadness.</p>
  </Layout>
)

export const Head = ({ location, data = {}, pageContext = {} }) => (
  <>
    <Helmet htmlAttributes={{ lang: "en", amp: "true" }}>
      <Seo
        title="404: Not found"
      />
    </Helmet>
  </>
)

export default NotFoundPage
